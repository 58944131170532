import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ma-10"
  }, [_c(VRow, [_c('h1', {
    staticClass: "text-h4 font-weight-bold primary--text ml-3"
  }, [_vm._v(" Neue Aufgabe ")])]), _c(VForm, {
    ref: "taskForm",
    model: {
      value: _vm.taskForm,
      callback: function ($$v) {
        _vm.taskForm = $$v;
      },
      expression: "taskForm"
    }
  }, [_c(VRow, {
    staticClass: "mt-4",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center text-overline font-weight-bold mt-3"
  }, [_c(VIcon, {
    staticClass: "mr-2 mb-1"
  }, [_vm._v("mdi-text")]), _vm._v("Titel ")], 1), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c(VCombobox, {
    attrs: {
      "outlined": "",
      "dense": "",
      "items": _vm.titles,
      "item-text": "title",
      "item-value": item => item,
      "rules": [v => !!v || 'Titel ist notwendig']
    },
    model: {
      value: _vm.task.title,
      callback: function ($$v) {
        _vm.$set(_vm.task, "title", $$v);
      },
      expression: "task.title"
    }
  })], 1)], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "text-overline font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mr-2 mb-1"
  }, [_vm._v("mdi-text-long")]), _vm._v("Beschreibung "), _c(VDivider)], 1)]), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-start"
  }, [_c('RichTextEditor', {
    ref: "richTextDescription",
    attrs: {
      "text": _vm.task.description,
      "placeholder": "Hier Text eingeben",
      "editable": ""
    }
  })], 1)])], 1), _c(VRow, {
    staticClass: "mt-7",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    staticClass: "mt-7 mt-xl-0",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "11",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center text-overline font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-calendar")]), _vm._v("Fälligkeitsdatum ")], 1), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c('LBADatepickerInput', {
    attrs: {
      "dense": "",
      "label": "Fälligkeitsdatum",
      "rules": [v => !!v || 'Fälligkeitsdatum ist notwendig']
    },
    model: {
      value: _vm.task.dueDate,
      callback: function ($$v) {
        _vm.$set(_vm.task, "dueDate", $$v);
      },
      expression: "task.dueDate"
    }
  })], 1)], 1)], 1), _c(VCol, {
    staticClass: "mt-7 mt-xl-0",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center text-overline font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-list-status")]), _vm._v("Status ")], 1), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.taskStates,
      "item-text": "description",
      "item-value": item => item,
      "rules": [v => !!v || 'Status ist notwendig'],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.task.status,
      callback: function ($$v) {
        _vm.$set(_vm.task, "status", $$v);
      },
      expression: "task.status"
    }
  })], 1)], 1)], 1)], 1), _c(VRow, {
    staticClass: "mt-7",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', [_c(VIcon, [_vm._v("mdi-clipboard-account")])], 1), _c('div', {
    staticClass: "ml-2 text-overline font-weight-bold"
  }, [_vm._v(" Bearbeiter ")]), _c('div', {
    staticClass: "ml-2"
  }, [_c(VBtn, {
    staticClass: "mt-n1 rounded-sm",
    attrs: {
      "icon": "",
      "x-small": "",
      "outlined": "",
      "tile": ""
    },
    on: {
      "click": _vm.openTaskAssigned
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)]), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [this.task.assignedUsers.length == 0 && this.task.assignedWorkGroups.length == 0 ? _c(VListItem, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-clipboard-account")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(" Noch kein Bearbeiter hinzugefügt ")])], 1)], 1) : _vm._e(), _vm._l(_vm.task.assignedUsers, function (usr) {
    return _c(VListItem, {
      key: usr.userId
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
  }), _vm._l(_vm.task.assignedWorkGroups, function (grp) {
    return _c(VListGroup, {
      key: grp.code,
      attrs: {
        "prepend-icon": "mdi-account-group"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(grp.name))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(grp.users, function (usr) {
      return _c(VListItem, {
        key: usr.userId
      }, [_c(VListItemIcon, [_c(VIcon, {
        attrs: {
          "small": "",
          "right": ""
        }
      }, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
    }), 1);
  })], 2)], 1)], 1)], 1), _c(VCol, {
    staticClass: "mt-7 mt-xl-0",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', [_c(VIcon, [_vm._v("mdi-package-variant-closed")])], 1), _c('div', {
    staticClass: "ml-2 text-overline font-weight-bold"
  }, [_vm._v(" Verlinkte Elemente ")]), _c('div', {
    staticClass: "ml-2"
  }, [_c(VBtn, {
    staticClass: "mt-n1 rounded-sm",
    attrs: {
      "icon": "",
      "x-small": "",
      "outlined": "",
      "tile": ""
    },
    on: {
      "click": _vm.openTaskObjects
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)]), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "12"
    }
  }, [_c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [_vm.objects.addresses.length == 0 && _vm.objects.addressesWithOutDocuments.length == 0 ? _c(VListItem, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-package-variant-closed")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(" Noch keine Verlinkung hinzugefügt ")])], 1)], 1) : _vm._e(), _vm._l(_vm.objects.addressesWithOutDocuments, function (address) {
    return _c(VListItem, {
      key: address.wfCid
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(address.displayName))])], 1)], 1);
  }), _vm._l(_vm.objects.addresses, function (address) {
    return _c(VListGroup, {
      key: address.wfCid,
      attrs: {
        "prepend-icon": "mdi-account"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(address.displayName))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(address.documents, function (document) {
      return _c(VListItem, {
        key: document.wfcid,
        attrs: {
          "dense": ""
        }
      }, [_c(VListItemIcon, [_c(VIcon, {
        attrs: {
          "small": "",
          "right": ""
        }
      }, [_vm._v("mdi-file-document")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(document.displayName))])], 1)], 1);
    }), 1);
  })], 2)], 1)], 1)], 1)], 1), _c(VFooter, {
    staticClass: "ml-3",
    attrs: {
      "absolute": "",
      "color": "white"
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": _vm.create
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-list-box")]), _vm._v("Aufgabe erstellen ")], 1)], 1)], 1), _c('TaskAssigned', {
    ref: "assigned",
    on: {
      "saved": _vm.savedAssigned
    }
  }), _c('DatePicker', {
    ref: "datePicker",
    on: {
      "datePicked": _vm.datePicked
    }
  }), _c('TaskObjects', {
    ref: "objects",
    on: {
      "save": _vm.saveObjects
    }
  }), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };